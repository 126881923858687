<template>
  <section content-title-features v-editable="blok" :id="props.blok?.id" :data-style="blok?.style || 'default'" :data-spacing="blok?.spacing || 'default'">
    <div class="container">
      <div class="row section-row">
        <h2 class="section-title" v-if="blok.title && title != ''" v-html="title"></h2>
        <div class="section-description" v-if="blok.description && description != ''" v-html="description"></div>
      </div>
      <div class="row section-row" v-if="blok?.features?.length > 0">
        <div class="section-features">
          <StoryblokComponent v-for="blok in blok.features" :key="blok._uid" :blok="blok" />
        </div>
      </div>
      <div class="row section-row">
        <div class="section-buttons">
          <StoryblokComponent v-for="blok in blok.buttons" :key="blok._uid" :blok="blok" />
        </div>
      </div>
    </div>
  </section>
</template>
<style src="~/assets/scss/section/content-title-features.scss" lang="scss"></style>
<script setup>
const props = defineProps({ blok: Object });
const title = computed(() => renderRichText(props?.blok?.title || {}));
const description = computed(() => renderRichText(props?.blok?.description || {}));
</script>
